import { graphql } from "gatsby";
import React from "react";
import { useWindowDimensions } from "@gravitywelluk/react-hooks";

import FieldTechnicalArticle from "../components/field-technical-article";
import Footer from "../components/footer";
import GetInTouch from "../components/get-in-touch";
import Header from "../components/header";
import HelmetWrapper from "../components/helmet-wrapper";
import {
  articleSchema,
  breadCrumbSchema
} from "../components/html-schema-snippets";
import Layout from "../components/layout";
import PageTop from "../components/page-top";
import PageTopArticleHeading from "../components/page-top-article-heading";
import { IProjectData } from "../pages/projects";
import { DynamicImageSrcString } from "./utils/dynamicImage";

interface IProjectPostData {
  nodeProject: IProjectData;
}

/**
 * Project
 */
const Project = ({ data }: { data: IProjectPostData }) => {
  const { width } = useWindowDimensions();
  const project = data.nodeProject;
  const tags = project.relationships.field_project_tags;

  const articleSchemaData = articleSchema({
    type: "Article",
    title: project.title,
    description: project.field_meta_description,
    path: project.path.alias,
    datePublished: project.created,
    dateModified: project.changed,
    author:
      project.relationships.author.relationships.profile__team_profile[ 0 ]
        .field_full_name,
    imageUrls: [ DynamicImageSrcString(project.relationships.field_cover_image) ]
  });

  const breadcrumbsSchemaData = breadCrumbSchema([
    {
      name: "Projects",
      path: "/projects"
    },
    {
      name: project.title,
      path: project.path.alias
    }
  ]);

  const schemaJson = [ breadcrumbsSchemaData, articleSchemaData ];

  /**
   * Dynamic background image depending the devices width
   */
  const backgroundImg = React.useMemo(() => {
    if (width && width < 600) {
      return DynamicImageSrcString(project.relationships.field_mobile_hero_image);
    }

    return DynamicImageSrcString(project.relationships.field_hero_image);
  }, [
    project.relationships.field_hero_image,
    project.relationships.field_mobile_hero_image,
    width
  ]);

  return (
    <Layout className="project">
      <>
        <HelmetWrapper
          title={project.title}
          description={project.field_meta_description}
          ogType="article"
          ogImage={project.relationships.field_meta_image}
          updatedAt={project.changed}
          canonicalPath={project.path.alias}
        >
          <script type="application/ld+json">
            {JSON.stringify(schemaJson)}
          </script>
        </HelmetWrapper>

        <header
          role="banner"
          id="hero"
        >
          <Header
            initialLogo
            initialTheme="dark"
          />

          <PageTop
            backgroundImage={backgroundImg}
            overlayColor="black"
            height="small"
          >
            <PageTopArticleHeading
              className="top-content"
              type="project"
              title={project.title}
              tags={tags}
            />
          </PageTop>

        </header>

        <main
          id="main-content"
          role="main"
        >
          <h1 className="off-screen">
            {project.title}
          </h1>

          <FieldTechnicalArticle
            type="project"
            paragraphs={project.relationships.field_technical_article}
          />
        </main>

        <footer role="contentinfo">
          <GetInTouch />

          <Footer />
        </footer>
      </>
    </Layout>
  );
};

export default Project;

export const query = graphql`
  query($slug: String!) {
    nodeProject(path: { alias: { eq: $slug } }) {
      ...getProjectData
    }
  }
`;
